import { MAP_DASH_SORT } from './index';

const paramsToQueryString = (params) => Object
  .keys(params)
  .map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

export default {
  categories: (baseUrl, instanceId) => `${baseUrl}/product/all?instanceId=${instanceId}&${paramsToQueryString({
    offset: 1,
    limit: 1,
  })}`,
  allProducts: (baseUrl, instanceId, params) => {
    const {
      offset,
      limit,
      sortBy,
      category: categoryId,
    } = params || {};
    const { key: sortKey, value: sortValue } = MAP_DASH_SORT[sortBy] || {};

    // offset it's a page number
    // limit it's a page size

    return `${baseUrl}/product/all?instanceId=${instanceId}&${paramsToQueryString({
      offset: 1,
      ...offset && limit && { offset: Math.ceil(offset / limit) + 1 || 1 },
      ...limit && { limit },
      ...(categoryId && { categoryId }),
      ...(sortKey && { [sortKey]: sortValue }),
    })}`;
  },
  allProductsForSingle: (baseUrl, instanceId) => `${baseUrl}/product/list?instanceId=${instanceId}`,
  product: (baseUrl, instanceId, productId) => `${baseUrl}/product/details/?instanceId=${instanceId}&productId=${productId}`,
  currency: (baseUrl, instanceId) => `${baseUrl}/settings/currency?instanceId=${instanceId}`,
};
