import { ECOM_PRODUCT_SELECTOR } from '../../../../../../constants';
import dom from '../../../../../../wrapper/DomWrapper';

import getElementSettings from './getElementSettings';

/**
 * Get product ids from the page
 * @returns {[Number]|[]}
 */
const getProductIds = () => {
  const elements = dom.getCollection(ECOM_PRODUCT_SELECTOR) || [];

  return [...elements].reduce((ids, element) => {
    const { productId } = getElementSettings(element) || {};

    if (!productId || ids.includes(productId)) return ids;

    return [
      ...ids,
      productId,
    ];
  }, []);
};

export default getProductIds;
