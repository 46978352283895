/**
 * Get the settings from the data-settings attribute of an element
 * @param {HTMLElement} element
 * @returns {any|{}}
 */
const getElementSettings = (element) => {
  try {
    return JSON.parse(element.dataset.settings) || {};
  } catch (error) {
    console.error(error);
  }
};

export default getElementSettings;
